import request from '@/libs/request'
/**
 * 热销职位列表
 */
export function getHotJobsListApi(unit_id) {
    return request({
        url: `/company/getJobList?unit_nature=${unit_id}`,
        method: 'get',
    })
}

/**
 * 全部热销职位列表
 */
export function getAllHotJobsListApi(page, limit) {
    return request({
        url: `/company/getJobList?page=${page}&limit=${limit}`,
        method: 'get',
    })
}


/**
 * 首页轮播图
 */
export function getSwiperListApi() {
    return request({
        url: '/carousel/getChartList',
        method: 'get',
    })
}
/**
 * 职位详情页
 */
export function getJobDetailListApi(id) {
    return request({
        url: `/company/getInfo/${id}`,
        method: 'get',
    })
}

/**
 * 搜索下拉初始化
 */
export function selectInitJobApi() {
    return request({
        url: '/selection/sele',
        method: 'get'
    })
}

/**
 *职位列表下拉选择条件搜索
 */
export function dropDownSearchJobApi(data, page, limit) {
    return request({
        url: `/company/unionSearchJob?page=${page}&limit=${limit}`,
        method: 'post',
        data
    })
}

/**
 * 学员列表下拉选择条件搜索/学员列表初始化
 */
export function dropDownSearchStudentApi(data, page, limit) {
    return request({
        url: `/userInfo/searchStaff?page=${page}&limit=${limit}`,
        method: 'post',
        data
    })
}

/**
 * 学员详情
 */
export function getStdDetailDataApi(user_id) {
    return request({
        url: `userInfo/staffInfo?id=${user_id}`,
        method: 'get'
    })
}