<template>
  <div class="job-item">
    <ul class="i-list" v-loading="loading">
      <li class="l-item" v-for="(item, index) in jobList" :key="index" @click="toJobDetail(item.id)">
        <div class="i-info">
          <div class="i-top">
            {{ item.job_name }}
          </div>
          <ul class="i-bottom">
            <!--薪酬 -->
            <li class="b-item t-money" v-if="item.min_salary_range ==0 || item.max_salary_range ==0">面议</li>
            <li class="b-item t-money" v-else>{{ item.min_salary_range }}-{{ item.max_salary_range}}k</li>
            <!--工作地点 -->
            <li class="b-item">{{ item.place_of_work }}</li>
            <!--招聘人数 -->
            <li class="b-item">招聘{{ item.job_num }}人</li>
            <!--教育程度 -->
            <!-- <li class="b-item">{{ item.education }}</li> -->
          </ul>
        </div>
        <ul class="i-company">
          <li class="c-image">
            <el-image :src="item.user_avatar" v-if="item.user_avatar" fit="scale-down"> </el-image>
            <el-image v-else>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </li>
          <li class="c-name">
            <p v-if="item.user_nickname">{{ item.user_nickname }}</p>
            <p v-else>十二方用户</p>
            
            <el-image v-if="item.user_auth_state == 3" :src="require('@/assets/images/auth1.png')" class="auth"></el-image>
            <el-image v-else :src="require('@/assets/images/auth2.png')" class="auth"></el-image>

            <el-image v-if="item.cert_company == 2" :src="require('@/assets/images/rz02.png')" class="enterprise"></el-image>
            <el-image v-else :src="require('@/assets/images/rz01.png')" class="enterprise"></el-image>
              <!-- <div class="c-type">{{ item.info.num_scale }}人</div> -->
          </li>
        </ul>
      </li>
      <li class="l-pagination">
        <Pagination :page.sync="page" :limit.sync="limit" :total="count" @getList="dropDownSearch" />
      </li>
    </ul>
    <div class="i-adv"></div>
  </div>
</template>

<script>
  import {
    dropDownSearchJobApi
  } from '@/api/home';
  import Pagination from '@/components/Pagination/index.vue'
  export default {
    name: 'jobItem',
    props: ['selectListVal'],
    components: {
      Pagination
    },
    data() {
      return {
        jobList: [], //招聘岗位信息
        loading: false,
        limit: 5,
        page: 1,
        count: 0,
        jobVal: {
          job_name: '',
          education: '',
          allocation: '',
          work_exp: '',
          salary_range: ''
        }
      }
    },
    watch: {
      selectListVal: {
        handler(val) {
          this.jobVal = val
          this.dropDownSearch()
        },
        deep: true,
        immediate: true
      }
    },
    created() {},
    methods: {
      //下拉选择条件搜索
      async dropDownSearch() {
        this.loading = true
        const {
          data: res
        } = await dropDownSearchJobApi(this.jobVal, this.page, this.limit)
        if (res.status != 10000) {
          return this.$message.error(res.message)
        }
        this.jobList = res.result.list
        this.count = res.result.count
        this.loading = false
      },
      //职业详情页
      toJobDetail(id) {
        this.$router.push(`/pc/job/detail?id=${id}`)
      },
    },

  }

</script>

<style lang="less" scoped>
  .job-item {
    width: 1200px;
    height: auto;
    margin: 0px auto;
    display: flex;

    .i-list {
      padding: 20px 0px;

      .l-item {
        width: 1200px;
        width: 1200px;
        height: 120px;
        background: #fff;
        box-sizing: border-box;
        padding-left: 60px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: box-shadow 1s;
        border-radius: 5px;

        &:hover {
          box-shadow: 2px 5px 10px gray;
        }

        .i-info {
          width: 600px;
          font-size: 15px;

          .i-bottom {
            display: flex;
            color: #8d92a1;
            font-size: 12px;

            .b-item {
              // border-left: 1px solid #8d92a1;
              padding: 0px 10px;
              margin: 10px 0px;
              line-height: 12px;
            }

            .b-item:first-child {
              padding-left: 0px;
              border-left: none;
            }

            .t-money {
              color: #fd7240;
            }
          }
        }

        .i-company {
          width: 300px;
          display: flex;
          font-size: 13px;

          .c-image{
              float: left;
          }

          .el-image {
            width: 54px;
            // height: 54px;
            border-radius: 50%;
            overflow: hidden;
          }

          .c-name {
            flex-direction: column;
            padding-left: 25px;

            p{
              width: 180px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 1.4;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              padding-left: 2px;
            }

            .auth{
              width: 19px;
              border-radius: initial;
            }
            .enterprise{
              width: 22px;
              margin-left: 5px;
            }

            .el-image{             
              margin-top: 10px;
              vertical-align: middle;
              img{
                height: auto;
              }
            }

            .i-content {
              color: #8d92a1;
              font-size: 13px;
              margin-top: 10px;

              .c-text {
                float: left;
                padding-right: 5px;
                line-height: 15px;
              }

              .c-type {
                float: left;
                line-height: 15px;
                padding: 0px 5px;
                border-left: 1px solid #8d92a1;
              }
            }
          }
        }
      }

      .l-pagination {
        width: 1200px;
        text-align: center;
      }
    }

    .i-adv {
      width: 300px;
      margin: 20px 0px;
      margin-left: 20px;
    }
  }

</style>
