<template>
  <div class="job-list">
    <div class="j-header">
      <div class="h-container">
        <!-- 搜索 -->
        <div class="m-search">
          <div class="s-contianer">
            <!-- <ul class="s-list">
              <li
                class="l-item"
                v-for="(item, index) in allocationList"
                :key="index"
                :class="selectListVal.allocation == item.id ? 'lightRed' : ''"
                @click="goSearch(item.id)"
              >
                {{ item.unit_nature }}
              </li>
            </ul> -->
            <div class="s-input">
              <el-input placeholder="请输入内容" v-model="input1" class="input-with-select">
                <el-select v-model="select" slot="prepend" placeholder="请选择">
                  <el-option label="找工作" value="1"></el-option>
                </el-select>               
              </el-input>
              <!-- <el-input
                placeholder="(例如：保安员)"
                v-model="searchVal"
                @change="goSearch()"
              >
                <el-select
                  v-model="allocation"
                  slot="prepend"
                  placeholder="职位类型"
                  @change="allocationStatus"
                  clearable
                >
                  <el-option
                    :label="item.unit_nature"
                    :value="index"
                    v-for="(item, index) in allocationList"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-input> -->
              <el-button size="medium" @click="goSearch()">搜索</el-button>
            </div>
            <!-- <div class="s-btn" v-if="isShow">
              <el-button plain @click="toLogin()">
                <i class="fa fa-sign-in"></i>
                <span>登录，查看更多信息</span>
              </el-button>
            </div> -->
          </div>
        </div>
        <!-- <div class="i-select">
          <ul class="s-contianer"> -->
            <!-- <li class="s-item">
              <div class="s-title">招聘单位</div>
              <div>
                <el-select
                  v-model="selectListVal.unit_nature"
                  size="small"
                  clearable
                  @change="unitNatureStatus"
                >
                  <el-option
                    v-for="(item, index) in unitNatureList"
                    :key="index"
                    :label="item"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </div>
            </li> -->
            <!-- <li class="s-item">
              <div class="s-title">学历要求：</div>
              <div>
                <el-select
                  v-model="selectListVal.education"
                  size="small"
                  clearable
                  @change="educationStatus"
                >
                  <el-option
                    v-for="(item, index) in educationList"
                    :key="index"
                    :label="item"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </div>
            </li> -->
            <!-- <li class="s-item">
              <div class="s-title">经验要求：</div>
              <div>
                <el-select
                  v-model="selectListVal.work_exp"
                  size="small"
                  clearable
                  @change="workExpStatus"
                >
                  <el-option
                    v-for="(item, index) in workExpList"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </li>
            <li class="s-item">
              <div class="s-title">期望薪酬：</div>
              <div>
                <el-select
                  v-model="selectListVal.salary_range"
                  size="small"
                  clearable
                  @change="salaryRangeStatus"
                >
                  <el-option
                    v-for="(item, index) in salaryRangeList"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </li> -->
          <!-- </ul> -->
          <!-- <div class="i-empty">
            <el-button type="text" @click="emptySelectValue()"
              >清空筛选条件</el-button
            >
          </div> -->
        <!-- </div> -->
      </div>
    </div>
    <job-item :selectListVal="selectListVal"></job-item>
  </div>
</template>

<script>
  import {
    selectInitJobApi
  } from '@/api/home'
  import jobItem from './components/jobItem.vue'
  export default {
    name: "jobList",
    components: {
      jobItem
    },
    data() {
      return {
        input1: '',
        select: '找工作',
        searchVal: '',
        educationList: {},
        salaryRangeList: {},
        allocationList: {},
        workExpList: {},
        selectListVal: {
          keyword: '',
          education: '',
          allocation: '',
          work_exp: '',
          salary_range: ''
        },
        isShow: false
      }
    },
    created() {
      this.goSearch()
      // this.getSelectInit()
      //获取传递的参数
      //   this.searchVal = this.$route.params.searchVal
      // this.selectListVal.allocation = this.$route.params.allocation
      // if (this.selectListVal.allocation) {
      //   this.goSearch(this.selectListVal.allocation)
      // }
    },
    methods: {
      //下拉数据初始化
      // async getSelectInit() {
      //   const {
      //     data: res
      //   } = await selectInitJobApi()
      //   if (res.status != 10000) {
      //     return this.$message.error('职位下拉列表数据初始化失败')
      //   }
      //   // console.log(res)
      //   this.educationList = res.result.education
      //   this.salaryRangeList = res.result.salary_range
      //   this.allocationList = res.result.allocation
      //   this.workExpList = res.result.work_exp
      // },
      // educationStatus(val) {
      //   this.selectListVal.education = val
      // },
      // allocationStatus(val) {
      //   this.selectListVal.allocation = val
      // },
      // workExpStatus(val) {
      //   this.selectListVal.work_exp = val
      // },
      // salaryRangeStatus(val) {
      //   this.selectListVal.salary_range = val
      // },
      //去登录
      toLogin() {
        //关闭登录弹出框
        this.$store.commit('saveloginDialog', true)
      },
      //搜索
      // goSearch(allocation_id) {
      //   this.selectListVal.job_name = this.searchVal
      //   this.selectListVal.allocation = allocation_id
      // },
      goSearch() {
        this.selectListVal.keyword = this.input1
        // console.log(this.input1);
        // this.selectListVal.allocation = allocation_id
      },
      //清空下拉选择项
      emptySelectValue() {
        // this.selectListVal.education = ''
        // this.selectListVal.allocation = ''
        // this.selectListVal.work_exp = ''
        // this.selectListVal.salary_range = ''
        // this.selectListVal.job_name = ''
        // this.searchVal = ''
        // this.allocation = ''
      }
    }
  }

</script>

<style lang="less" scoped>
/deep/.el-select .el-input__inner:focus {
  border-color: @primaryColor;
}

.selected {
  color: @primaryColor;
}

.lightRed {
  background: @primaryColor;
  border: 1px solid transparent;
  color: #fff;
}

.job-list {
  .j-header {
    .h-container {
      width: 1200px;
      margin: 0px auto;
      padding-top: 20px;
      border-radius: 5px;

      .m-search {
        .s-contianer {
          width: 1200px;
          background: #fff;

          .s-list {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            padding: 0px 20px;

            .l-item {
              line-height: 32px;
              border: 1px solid #ddd;
              padding: 0px 10px;
              font-size: 15px;
              border-radius: 5px;
              margin-right: 20px;
              cursor: pointer;
              box-shadow: 2px 5px 10px gray;
              &:hover {
                color: #fff;
                background: @primaryColor;
                border: 1px solid transparent;
              }
            }
          }

          .s-input {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            /deep/ .el-input__inner {
              width: 100%;
              height: 50px;
              border: none;
              font-size: 16px;
            }

            .el-select {
              /deep/.el-input__inner {
                width: 120px;
                border: none;
                font-size: 16px;
              }
            }

            .el-input {
              width: 70%;
              height: 50px;
              box-sizing: border-box;
              border: 2px solid @primaryColor;
              border-right: 0px;
              border-radius: 5px 0px 0px 5px;
              padding: 1px;
            }

            /deep/ .el-input-group__prepend {
              border: none;
              border-radius: 0px;
              font-size: 16px;
              background: @primaryColor;
              color: #fff;
              border-radius: 4px 0px 0px 4px;

              .el-input__icon {
                color: #fff;
              }

              .el-input__inner::placeholder {
                color: #fff;
              }
            }

            .el-button {
              height: 56px;
              border-radius: 0px;
              font-size: 16px;
              color: #fff;
              background: @primaryColor;
              border: 2px solid @primaryColor;
              border-radius: 0px 5px 5px 0px;
            }
          }

          .s-btn {
            width: 280px;
            height: 52px;
            margin-left: 20px;
            position: relative;

            .el-button {
              width: 100%;
              height: 100%;
              font-size: 16px;
              color: #fff;
              background: @primaryColor;

              i {
                font-size: 20px;
                position: absolute;
                top: 30%;
                left: 15%;
              }
            }

            .el-button:hover,
            .el-button:focus {
              border-color: @primaryColor;
            }
          }
        }
      }

      .i-select {
        box-sizing: border-box;
        width: 1200px;
        height: 60px;
        padding: 0px 20px;
        margin-top: 10px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;

        .s-title {
          height: 32px;
          line-height: 32px;
        }

        .s-contianer {
          width: 100%;
          display: flex;
        }

        .s-item {
          display: flex;

          /deep/ .el-input__inner {
            color: @primaryColor;
          }
        }

        /deep/.el-select {
          width: 90%;
          border-color: @primaryColor;
        }

        /deep/.el-input {
          border-color: @primaryColor;
        }
      }

      .i-empty {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        text-align: right;

        .el-button {
          font-size: 15px;
        }
      }
    }
  }
}
</style>
