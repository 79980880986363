<template>
  <div>
    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="limit"
      layout="total,prev, pager, next" background :total="total">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    name: 'pagination',
    props: {
      page: Number,
      total: Number,
      limit: Number
    },
    methods: {
      handleCurrentChange(val) {
        this.$emit('update:page', val)
        this.$emit('getList')
      }
    }
  }

</script>
<style lang="less" scoped>
  /deep/.el-pagination .number:hover {
    color: #e1251b !important;
  }

  /deep/.is-background .el-pager li:not(.disabled).active {
    background: #e1251b;
    color: #fff;
  }
  /deep/.active:focus {
    background: #e1251b;
    color: #fff !important;
  }


</style>
